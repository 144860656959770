const Theme = {
	// palette: {
	// 	primary: {
	// 		contrastText: "#FFFFFF",
	// 		dark: "#FFFFFF",
	// 		main: "#FFFFFF",
	// 		light: "#FFFFFF",
	// 	},
	// },
	// overrides: {
	// 	MuiOutlinedInput: {
	// 		root: {
	// 			position: "relative",
	// 			"& $notchedOutline": {
	// 				borderColor: "#FFFFFF",
	// 			},
	// 			"&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
	// 				borderColor: "#FFFFFF",
	// 				"@media (hover: none)": {
	// 					borderColor: "#FFFFFF",
	// 				},
	// 			},
	// 			"&$focused $notchedOutline": {
	// 				borderColor: "#FFFFFF",
	// 				borderWidth: 1,
	// 			},
	// 		},
	// 	},
	// },
};

export default Theme;
